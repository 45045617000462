<template>
  <v-sheet>
    <v-list-item-group
      color="transparent"
    >
      <v-list-item
        v-for="item in list"
        :key="item.id"
        dense
        class="px-0"
      >
        <cameraController 
          :id="item.id" 
          :itemtime="item.time"
          :originalitem="item"
          @setCamera="setCamera"
          @playCamera="playCamera"
        />
      </v-list-item>
      <v-divider></v-divider>
    </v-list-item-group>
  </v-sheet>
</template>

<script>
import cameraController from './camera-controller';

export default {
  name: "camera-lister",
  components: {
    cameraController
  },
  data: () => ({
    list: []
  }),
  props: {
  },
  watch: {
    cameras() {
      this.setupList()
    }
  },
  computed: {
    cameras() {
      return this.$store.state.camera.all
    },
  },
  methods: {
    setupList() {
      this.list = this.cameras.map(item => {
        return item
      })
      if (this.list[0]) this.list[0].time = 0
    },
    setCamera(item) {
      this.$emit('setCamera', item)      
    },
    playCamera(item) {
      this.$emit('playCamera', item)      
    },
  },
  mounted() {
    this.setupList()
  }
}
</script>

<style lang="scss" scoped>
</style>