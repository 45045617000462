<template>
  <SketchfabPlayer />
</template>

<script>
import SketchfabPlayer from '../components/sketchfab-player.vue'

export default {
  name: 'sketchfab-player',
  components: {
    SketchfabPlayer
  }
}
</script>
