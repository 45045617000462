import { v4 as uuidv4 } from 'uuid';

const state = {
  all:[],
  currentid: null,
  thiscamera: 0,
  nextcamera: 0,
  cameramoves: false,
  sequenceData: {
    camera: [],
    target: [],
    fov: []
  }
}

const getters = {
  getThisCamera: (state) => (time) => {
    if (!state.all[0]) return null
    let thisCam = state.all[0]
    state.all.forEach(cam => {
      if (cam.time <= time ) {
        if (time - cam.time < time - thisCam.time) {
          thisCam = cam
        }
      }
    })
    return state.all.findIndex(cam => cam.id === thisCam.id)
  },
  getNextCamera: (state) => (time) => {
    if (!state.all[1]) return null
    let nextCam = state.all.find(cam => cam.time > time)
    if (nextCam) {
      return state.all.findIndex(cam => cam.id === nextCam.id)
    } else return null
  },
  getSelectedcamera: (state) => {
    return state.all.find(item => item.id === state.currentid)
  }
}

const actions = {
}

const mutations = {
  clearCamera (state) {
    state.all = []
  },
  clearSequenceData (state) {
    state.sequenceData = {
      camera: [],
      target: [],
      fov: []
    }
  },
  setSequenceData (state, payload) {
    state.sequenceData.camera.push(payload.camera)
    state.sequenceData.target.push(payload.target)
    state.sequenceData.fov.push(payload.fov)
  },
  setSelectedcamera (state, id) {
    state.currentid = id
  },
  setThiscamera (state, id) {
    state.thiscamera = id
  },
  setNextcamera (state, id) {
    state.nextcamera = id
  },
  setCameraMoves(state, doesmove) {
    state.cameramoves = doesmove;
  },
  addCamera (state, camera) {
    camera.id = uuidv4()
    if (state.all.length > 0 && !camera.time) {
      camera.time = state.all[state.all.length-1].time + 1
    }
    state.all.push(camera)
    state.currentid = camera.id
  },
  replaceCamera (state, payload) {
    let newlist = []
    state.all.forEach(cam => {
      if (cam.id === payload.id) {
        console.log('BABY')
        console.log("payload.camera", payload.camera);
        const newCam = {...cam, ...payload.camera}
        console.log("newCam", newCam);
        newlist.push(newCam)
      } else {
        newlist.push(cam)
      }
    })
    state.all = newlist
  },
  removeCamera (state, id) {
    let newlist = []
    state.all.forEach(cam => {
      if (cam.id !== id) newlist.push(cam)
    })
    state.all = newlist
  },
  setCameralist (state, newlist) {
    state.all = newlist
  },
  setCameraHotspot (state, payload) {
    let newlist = []
    state.all.forEach(cam => {
      if (cam.id === payload.cameraid) {
        cam.hotspot = payload.hotspotid
      }
      newlist.push(cam)
    })
    state.all = newlist
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
