<script>
import player from '../tools/sketchfab/player'

export default {
  name: 'searchresult-card',
  components: {
  },
  props: {
    image: String,
    title: String,
    sceneuid: String,
    readOnly: Boolean
  },
  data: function () {
    return {
    }
  },  
  computed: {
  },
  mounted: function () {
  },
  methods: {
    loadModel() {
      if (!this.readOnly) {
        this.$store.commit('setSceneuid', this.sceneuid)
        player.loadScene('player__iframe', this.sceneuid)
        .then(() => {
          this.$store.commit('setLoaddialog', false)
        })     
      }
    },
  }
}
</script>

<template>
  <v-card
    v-if="sceneuid"
    width="300px"
  >
    <v-img
      :src="image"
      height="200px"
      @click="loadModel"
    ></v-img> 
    <v-btn text block @click="loadModel" class="text-truncate d-inline-block text-left" max-width="300px">
      {{ title }}
    </v-btn>       
  </v-card>
</template>

<style lang="scss" scoped>
</style>
