<template>
  <div>
    <v-divider></v-divider>
      <div 
        v-if="item"
        class="d-flex flex-column"
      >

        <v-toolbar
          width="100%"
          dense
          flat
          class="pa-0 pr-2"
        >
          <v-sheet width="90" class="ml-3 text-subtitle-1">
            FOV
          </v-sheet>            
          <v-sheet class="text-body-2 text-secondary">
            field of view (camera angle)
          </v-sheet>
          <v-spacer></v-spacer>
          <v-sheet width="200">
            <v-text-field
              class="mt-0"
              single-line
              hide-details="true"
              v-model="fov"
              type="number"
              min="0.1"
              max="120"
              step=0.1
              @input="refreshCamera"
              suffix="degrees"
            ></v-text-field>
          </v-sheet>
        </v-toolbar>

        <v-toolbar
          width="100%"
          dense
          flat
          class="pa-0 pr-2"
        >
          <v-sheet width="90" class="ml-3 text-subtitle-1">
            Easing
          </v-sheet>            
          <v-sheet class="text-body-2 text-secondary">
            changes the speed of motion
          </v-sheet>
          <v-spacer></v-spacer>
          <v-sheet width="200">
            <v-select
              class="mt-0"
              single-line
              hide-details="true"
              v-model="item.easing"
              :items="easings"
              label="Select easing"
              @change="refreshCamera"
            ></v-select>
          </v-sheet>
        </v-toolbar>

        
        <v-toolbar
          width="100%"
          dense
          flat
          class="pa-0 pr-2"
        >
          <v-sheet width="90" class="ml-3 text-subtitle-1">
            Spin
          </v-sheet>            
          <v-sheet class="text-body-2 text-secondary">
            adds extra orbits to the camera
          </v-sheet>

          <v-spacer></v-spacer>
          <v-sheet width="100">
            <v-text-field
              class="mt-0"
              single-line
              hide-details="true"
              v-model="item.spin"
              label="Amount"
              type="number"
              min="-3"
              max="3"
              step=1
              @change="refreshCamera"
              :disabled="item.motion === 'straight'"
            ></v-text-field>
          </v-sheet>
        </v-toolbar>

        <v-toolbar
          width="100%"
          dense
          flat
          class="pa-0 pr-2"
        >
          <v-sheet width="90" class="ml-3 text-subtitle-1">
            Stir
          </v-sheet>            
          <v-sheet class="text-body-2 text-secondary">
            moves the camera in little circles
          </v-sheet>

          <v-spacer></v-spacer>
          <v-sheet width="200">
            <div class="d-flex">
              <v-text-field
                class="mr-2"
                v-model="item.circleRadius"
                single-line
                hide-details="true"
                prefix="radius: "
                type="number"
                min="-100"
                max="100"
                step=0.1
                @change="refreshCamera"
                :disabled="item.motion === 'straight'"
              ></v-text-field>
              <v-text-field
                v-model="item.circleCount"
                single-line
                hide-details="true"
                prefix="count: "
                type="number"
                min="1"
                max="100"
                step=1
                @change="refreshCamera"
                :disabled="item.motion === 'straight'"
              ></v-text-field>
            </div>
          </v-sheet>
        </v-toolbar>

        <v-toolbar
          width="100%"
          dense
          flat
          class="pa-0 pr-2"
        >
          <v-sheet width="90" class="ml-3 text-subtitle-1">
            Motion
          </v-sheet>            
          <v-sheet class="text-body-2 text-secondary">
            pick how the camera moves
          </v-sheet>
          <v-spacer></v-spacer>
          <v-sheet width="200">
            <v-select
              class="mt-0"
              single-line
              hide-details="true"
              v-model="item.motion"
              :items="motions"
              label="Select motion"
              @change="refreshCamera"
            ></v-select>
          </v-sheet>
        </v-toolbar>

        <v-toolbar
          width="100%"
          dense
          flat
          class="pa-0 pr-2"
          v-if="hasRoll"
        >
          <v-sheet width="90" class="ml-3 text-subtitle-1">
            Roll
          </v-sheet>            
          <v-sheet class="text-body-2 text-secondary">
            tilt the camera sideways
          </v-sheet>
          <v-spacer></v-spacer>
          <v-sheet 
            width="200"
          >
            <v-slider
              class=""
              v-model="item.roll"
              @end="refreshCamera"
              min="-50"
              max="50"
              step=1
              hide-details=true
              thumb-size="24"
              thumb-label
            >
              <template v-slot:append>
                <v-icon
                  @click="resetRoll"
                >
                  mdi-restore
                </v-icon>
              </template>            
            </v-slider>

          </v-sheet>
        </v-toolbar>

        <v-toolbar
          width="100%"
          dense
          flat
          class="py-0 px-2"
          color="grey lighten-3"
        >
          <v-btn
            @click="copyCam"
            text
          >
            Copy
          </v-btn>
          <v-btn
            @click="pasteEye"
            text
          >
            Paste eye
          </v-btn>
          <v-btn
            @click="pasteTarget"
            text
          >
            Paste target
          </v-btn>
          <v-btn
            class="ml-auto mt-auto mr-0"
            @click="removeItem"
            icon
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
      
      </div>

  </div>
</template>

<script>
import camera from '@/tools/sketchfab/camera';
import animate from '@/tools/sketchfab/animate';

export default {
  name: "camera-properties",
  components: {
  },
  data: () => ({
    item: null,
    easings: [
      'linear',
      'sine',
      'quad',
      'cubic',
      'exponential',
      'slomo',
      'vertigo',
    ],
    motions: [
      'orbit',
      'straight'
    ],
    fov: 45
  }),
  props: {
  },
  watch: {
    selectedcamera(newValue) {
      this.item = {...this.cameras.find(item => item.id === newValue)}
      this.fov = this.item.fov
    },
  },
  computed: {
    cameras() {
      return this.$store.state.camera.all
    },
    selectedcamera() {
      return this.$store.state.camera.currentid
    },
    hasRoll() {
      return this.$store.state.ui.canroll === true
    },
  },
  methods: {
    removeItem() {
      this.$store.commit('camera/removeCamera', this.selectedcamera)

      if (this.cameras.length > 0) {
        this.$store.commit('camera/setSelectedcamera', this.cameras[0].id)
      } else {
        this.$store.commit('camera/setSelectedcamera', null)
      }
    },
    setCamera(item) {
      this.$store.commit('time/setSequencePlaying', false)
      this.$emit('setCamera', item)
    },
    resetRoll() {
      this.item.roll = 0
      this.refreshCamera()
    },
    async refreshCamera() {
      this.$store.commit('time/setSequencePlaying', false)

      const newcam = await camera.captureCamera()
      newcam.id = this.selectedcamera
      newcam.time = this.item.time
      newcam.spin =  parseInt(this.item.spin)
      newcam.circleRadius = parseFloat(this.item.circleRadius)
      newcam.circleCount = parseFloat(this.item.circleCount)
      newcam.easing = this.item.easing
      newcam.motion = this.item.motion
      newcam.roll = parseFloat(this.item.roll)
      newcam.fov = +(this.fov)
      newcam.width = animate.getVertigoWidth(newcam.radius, newcam.fov)
      camera.setFov(newcam.fov)
      this.item = newcam
      this.$store.commit('camera/replaceCamera', {id: this.selectedcamera, camera: newcam})
      
      this.setCamera(newcam)

    },
    copyCam() {
      this.$store.commit('setCopyBuffer', { ...this.item})
    },
    pasteEye() {
      if (this.$store.state.copyBuffer) {
        this.item.position = this.$store.state.copyBuffer.position
        this.item.latlon = this.$store.state.copyBuffer.latlon
        this.item.radius = this.$store.state.copyBuffer.radius
        this.$store.commit('camera/replaceCamera', {id: this.item.id, camera: this.item})

        this.setCamera(this.item)
      }
    },
    pasteTarget() {
      if (this.$store.state.copyBuffer) {
        this.item.target = this.$store.state.copyBuffer.target
        this.$store.commit('camera/replaceCamera', {id: this.item.id, camera: this.item})

        this.setCamera(this.item)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>