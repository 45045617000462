import player from './player'
import viewer from '../viewer'

const setBackground = function (settings, callback) {
  if (player.api) {
    player.api.setBackground(settings, function () {
      if (callback != null) callback()
    })
  }
}

const setBackgroundHex = function (hex, callback) {
  const RGB = viewer.convertHexToRGB(hex)
  if (player.api) {
    player.api.setBackground({color: [RGB.r / 255, RGB.g / 255, RGB.b / 255]}, function () {
      if (callback != null) callback()
    })
  }
}

const getBackgroundList = function () {
  if (player.api) {
    player.api.getBackgroundList(function (result) {
      console.log("result", result);
    })
  }
}

export default { 
  setBackground,
  setBackgroundHex,
  getBackgroundList
}
