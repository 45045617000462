<template>
  <v-card
    class="camcontroller pr-1"
    flat
    color="transparent"
  >
    <div
      class="d-flex"
    >
      <v-btn
        icon
        @click="selectCamera(item)"
        color="primary"
        class="mt-1"
      >
        <v-icon v-if="isSelected">mdi-radiobox-marked</v-icon>
        <v-icon v-else>mdi-radiobox-blank</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="playCamera(item)"
        color="primary"
        class="mt-1"
        :disabled="isLast"
      >
        <v-icon >mdi-play</v-icon>
      </v-btn>      
      
      <v-slider
        v-if="item"
        class="mb-n6 pt-2"
        v-model="time"
        @end="updateTime"
        :max="duration"
        thumb-label
        hide-details=true
      ></v-slider>

      <v-btn
        class="ml-auto mt-1"
        text
        @click="retakeCamera"
      >
        Retake
        <v-icon>mdi-camera-retake-outline</v-icon>
      </v-btn>
    </div>  
  </v-card>
</template>

<script>
import camera from '@/tools/sketchfab/camera';
import animate from '@/tools/sketchfab/animate';

export default {
  name: "camera-controller",
  components: {
  },
  data: () => ({
    item: null,
    time: 0
  }),
  props: {
    id: String,
    itemtime: Number,
    originalitem: Object,
  },
  watch: {
    originalitem(newValue) {
      this.item = {...newValue}
    },
    itemtime(newValue) {
      this.time = newValue
    },
  },
  computed: {
    cameras() {
      return this.$store.state.camera.all
    },
    duration() {
      return this.$store.state.duration
    },
    isSelected() {
      return this.$store.state.camera.currentid === this.id
    },
    isLast() {
      const idx = this.cameras.findIndex(item => item.id === this.id)
      return idx === this.cameras.length-1
    },
  },
  methods: {
    playCamera(item) {
      this.$emit('playCamera', item)
    },
    setCamera(item) {
      this.$store.commit('time/setSequencePlaying', false)
      this.$emit('setCamera', item)
    },
    async retakeCamera() {
      this.$store.commit('time/setSequencePlaying', false)
      const newData = await camera.retakeCamera()
      let newCam = {...this.item}
      newCam.position = newData.position
      newCam.target = newData.target
      newCam.latlon = newData.latlon
      newCam.radius = newData.radius
      newCam.width = animate.getVertigoWidth(newData.radius, newCam.fov)
      this.$store.commit('camera/replaceCamera', {id: newCam.id, camera: newCam})
    },
    updateTime() {
      let newList = this.cameras.map(cam => {
        if (cam.id === this.item.id) {
          return {...this.item, ...{time: this.time}}
        } else {
          return {...cam}
        }
      })
      // reorder the list based on their times
      newList = newList.sort( (a, b) => {
        return a.time - b.time
      })
      newList[0].time = 0
      if (newList[0].id === this.id) this.time = 0
      this.$store.commit('camera/setCameralist', newList)
    },
    selectCamera(item) {
      this.setCamera(item)
      this.$store.commit('camera/setSelectedcamera', item.id)
    },
  },
  mounted() {
    this.item = {...this.originalitem}
    this.time = this.item.time
  }
}
</script>

<style lang="scss" scoped>
.camcontroller {
  width: 100%;
}
</style>