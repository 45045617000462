<script>
import player from '../tools/sketchfab/player'
import axios from 'axios'
import SearchCard from  './searchresult-card'
import CaptureSettings from  './settings/capture-settings'
import ViewerSettings from  './settings/viewer-settings'

export default {
  name: 'selection-sheet',
  components: {
    SearchCard,
    CaptureSettings,
    ViewerSettings,
  },
  props: {
  },
  data: function () {
    return {
      query: '',
      queryResults: [],
      singleQueryResult: {},
      snackbar: false,
      pastedData: '',
      importedId: null,
      importedCameras: null,
    }
  },  
  computed: {
    sceneuid: {
      get: function() {
        return this.$store.state.sceneuid
      },
      set: function(newValue) {
        this.$store.commit('setSceneuid', newValue)
      },
    },
    nextCursor() {
      return this.$store.state.nextCursor;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    nextModelsAvailable() {
      return this.nextCursor && this.nextCursor.length !== null;
    },
    cameras() {
      return this.$store.state.camera.all
    },
    loaddialog: {
      get () {
        return this.$store.state.loaddialog
      },
      set () {
        this.$store.commit('setLoaddialog', false)
      }
    },
    tab: {
      get () {
        return this.$store.state.dialogtab
      },
      set (newValue) {
        this.$store.commit('setDialogtab', newValue)
      }
    },
  },
  mounted: function () {
  },
  methods: {
    loadModel() {
      player.loadScene('player__iframe', this.$store.state.sceneuid)
      .then(() => {
        this.loaddialog = false
      })
    },
    pushQueryResults(results) {
      results.forEach(item => {
        const model = {
          sceneuid: item.uid,
          image: item.thumbnails?.images[0].url,
          title: item.name
        }
        this.queryResults.push(model)
      })
    },
    searchPressEnter(event) {
      if (event.key === 'Enter') {
        this.searchModel()
      }
    },
    getSingleModel(id) {
      const config = {
        method: "get",
        url: `https://api.sketchfab.com/v3/models/${id}`,
        headers: {},
      };
      axios(config)
      .then(response => {
        console.log("response", response);
        if (response.data && response.status === 200) {
          this.singleQueryResult = {
            sceneuid: response.data.uid,
            image: response.data.thumbnails?.images[0].url,
            title: response.data.name
          }
        } else this.singleQueryResult = {}
      })
    },
    searchModel() {
      const config = {
        method: "get",
        url: `https://api.sketchfab.com/v3/search?type=models&q=${this.query}`,
        headers: {},
      };
      axios(config)
      .then(response => {
        console.log("response", response);
        if (response.data.results.length > 0) {
          this.$store.commit("setNextCursor", response.data.next);
          this.queryResults = []
          this.pushQueryResults(response.data.results)
        } else this.queryResults = []
      })
    },
    getNext(entries) {
      console.log("this.nextCursor", this.nextCursor);
      if (entries[0].isIntersecting && this.nextModelsAvailable) {
        const config = {
          method: "get",
          url: this.nextCursor,
          headers: {},
        };        
        axios(config)
        .then((response) => {
          if (this.queryResults && response.data.results) {
            this.$store.commit("setNextCursor", response.data.next);
            this.pushQueryResults(response.data.results)

          //   this.$store.commit("addModels", response.data.results);
          //   // this.updateTags();
          //   console.log("next home models", response.data);
          }
        });
      }
    },    
    keepModel() {
      this.$store.commit('setLoaddialog', false)
    },
    exportCameras() {
      const data = JSON.stringify({
        cameras: this.cameras,
        id: this.sceneuid
      })

      console.log("data", data);
      navigator.clipboard.writeText(btoa(data));
      this.snackbar = true

      // console.log("window.location.href", window.location.href.split('?')[0]);
      // new URL(
      //         theChannels[channelName].textureurl,
      //         window.location.href
      //       ).href;
    },
    shareViewerUrl() {
      const sequence = btoa(JSON.stringify(this.cameras))
      const url = `${window.location.origin}?viewer=1&sceneuid=${this.sceneuid}&sequence=${sequence}`
      navigator.clipboard.writeText(url);
      this.snackbar = true
    },
    dataInput() {
      this.importedId = null
      this.importedCameras = null

      const data = this.parsePastedData()
      if (data.id) {
        this.importedId = data.id
        this.getSingleModel(this.importedId)
      }
      if (data.cameras?.length > 0) {
        this.importedCameras = data.cameras
      }
    },
    parsePastedData () {
      this.snackbar = false
      const data = JSON.parse(atob(this.pastedData))
      console.log("data", data);
      return data
    },
    addCameras(data) {
      if (data.cameras.length > 0) {
        this.$store.commit('camera/clearCamera')
        data.cameras.forEach(cam => {
          this.$store.commit('camera/addCamera', cam)
        })
      }

    },
    loadCameras() {
      const data = this.parsePastedData()
      this.addCameras(data)
      this.keepModel()
    },
    loadAll() {
      const data = this.parsePastedData()
      this.sceneuid = data.id
      player.loadScene('player__iframe', this.$store.state.sceneuid)
      .then(() => {
        this.addCameras(data)
      })      
    }
  }
}
</script>

<template>
  <v-dialog
    v-model="loaddialog"
    fullscreen
  >  
    <v-toolbar
      flat
      dark
      color="pink darken-3"
    >
      <v-btn
        icon
        dark
        @click="keepModel"
        class="ml-3"
      >
      <v-icon large>mdi-close</v-icon>
      </v-btn>      
      <v-tabs
        v-model="tab"
        centered
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab>
          Load new model
          <v-icon>mdi-atom</v-icon>
        </v-tab>

        <v-tab>
          Import, export
          <v-icon>mdi-swap-vertical-bold</v-icon>
        </v-tab>

        <v-tab>
          Capture settings
          <v-icon>mdi-camera</v-icon>
        </v-tab>

      </v-tabs>
    </v-toolbar>

    <v-tabs-items 
      v-model="tab"
      style="height: calc(100vh - 64px)"
    >
      <v-tab-item
        style="height: 100%"

      >
        <v-toolbar
          class="px-4"
        >
          <div class="text-h4 font-weight-thin">Pick a Sketchfab model</div>
          <v-spacer></v-spacer>



          <template v-slot:extension>
            <v-text-field
              v-model="sceneuid"
              hint="Enter Sketchfab scene uid"
              label="Scene uid"
              single-line
              hide-details
              class="mr-8"
            >
              <template v-slot:append-outer>
                <v-btn
                  @click="loadModel"
                  class="mt-n2"
                  outlined
                >
                  Load model from ID 
                </v-btn> 
              </template>
            </v-text-field> 

            <v-text-field
              v-model="query"
              hint="Search a scene by name"
              label="Search"
              single-line
              hide-details
              @keyup="searchPressEnter"
            >
              <template v-slot:append-outer>
                <v-btn
                  @click="searchModel"
                  class="mt-n2"
                  outlined
                >
                  Search model
                </v-btn> 
              </template>
            </v-text-field> 

            <v-progress-linear
              :active="isLoading"
              :indeterminate="isLoading"
              absolute
              bottom
              color="pink darken-3"
            ></v-progress-linear>        
          </template> 
        </v-toolbar>

        <div 
          v-if="queryResults.length > 0"
          class="searchresults d-flex flex-wrap justify-center fill-height pt-4"
        >
          <SearchCard 
            v-for="(item, index) in queryResults"
            :key="index"
            :title="item.title"
            :sceneuid="item.sceneuid"
            :image="item.image"
            :readOnly=false
            class="ma-2"
          />
          <v-skeleton-loader
            v-if="nextModelsAvailable"
            v-intersect="getNext"
            type="image, article, button"
          />        
        </div>
      </v-tab-item>

      <v-tab-item class="px-4">
        <v-row>
          <v-col cols=6 class="d-flex flex-column">
            <div class="text-h6">Export data or share URL</div>
            <ViewerSettings
              @snackbar="snackbar = true"
            />
            <!-- <v-btn
              @click="exportCameras"
              outlined
              class="mt-2"
            >
              Export model and cameras
            </v-btn> -->
          </v-col>

          <!-- <v-col cols=6>
            <div class="text-h6">Paste your data here</div>
            <v-textarea
              label="Paste data"
              v-model="pastedData"
              @input="dataInput"
            >
            </v-textarea>

            <div
              class="d-flex flex-column"
            >
              <SearchCard 
                v-if="singleQueryResult"
                :title="singleQueryResult.title"
                :sceneuid="singleQueryResult.sceneuid"
                :image="singleQueryResult.image"
                :readOnly=true
                class="ma-2"
              />

              <v-list-item v-if="importedCameras">
                <v-list-item-content>
                  <v-list-item-title>{{importedCameras.length}} Cameras</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-btn
                @click="loadCameras"
                outlined
                class="mx-2 mt-2"
              >
                Import cameras only
              </v-btn>

              <v-btn
                @click="loadAll"
                outlined
                class="mx-2 mt-2"
              >
                Import cameras and model
              </v-btn>
              

            </div>
          </v-col> -->
        </v-row>

        


      </v-tab-item>
    <v-tab-item>
      <CaptureSettings />

    </v-tab-item>

    </v-tabs-items>

    <v-snackbar
      v-model="snackbar"
      timeout=2000
    >
      Data copied to clipboard
      <template v-slot:action="{ attrs }">
        <v-btn
          color="accent"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>    
  </v-dialog>
</template>

<style lang="scss" scoped>
.searchresults {
  overflow: auto;
  height: calc(100% - 112px);
}
</style>
