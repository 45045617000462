import player from './player'

const setPostProcessing = function (settings, callback) {
  player.api.setPostProcessing(settings, function () {
    if (callback != null) callback()
  })
}

const getPostProcessing = function () {
  return new Promise(resolve => {
    player.api.getPostProcessing(function (settings) {
      // console.log(settings)
      resolve(settings)
    })
  })
}

// window.configuratorapi.setPostProcessing = setPostProcessing
// window.configuratorapi.getPostProcessing = getPostProcessing

export default { setPostProcessing, getPostProcessing }
