const convertRGBtoHex = function (rgb) {
  return "#" + colorValueToHex(rgb.r) + colorValueToHex(rgb.g) + colorValueToHex(rgb.b);
}

const convertHexToRGB = function (hex) {
  var red = parseInt(hex[1]+hex[2],16);
  var green = parseInt(hex[3]+hex[4],16);
  var blue = parseInt(hex[5]+hex[6],16);
  return {r: red, g: green, b: blue}
}

function colorValueToHex(color) {
  var hexadecimal = color.toString(16);
  return hexadecimal.length == 1 ? "0" + hexadecimal : hexadecimal;
}

export default {
  convertRGBtoHex,
  convertHexToRGB
}