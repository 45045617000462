import Vue from 'vue'
import Vuex from 'vuex'
import time from './modules/time'
import camera from './modules/camera'
import capture from './modules/capture'
import ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    duration: 10,
    aspectratio: null,
    hotspots: [],
    animationsection: null,
    containerSize: {x: 100, y: 100},
    playergui: false,
    playervignette: false,
    playercameraconstraints: false,
    projectsdialog: false,
    sceneuid: 'dGUrytaktlDeNudCEGKk31oTJY',
    sceneLoaded: false,
    isLoading: false,
    playersettings: {
      bare: {
        autospin: 0.0,
        preload: 0,
        ui_stop: 0,
        ui_infos: 0, // need biz account
        ui_controls: 0, // need biz account
        ui_watermark: 0,
        scrollwheel: 1,
        graph_optimizer: 1,
        merge_materials: 1,
        transparent: 0,
        dof_circle: 0,
        camera: 0,
        ui_hint: 0,
      },
      untouched: {
        autospin: 0.0,
        preload: 0,
        graph_optimizer: 1,
        ui_hint: 0,
        camera: 0
      }
    },
    nextCursor: null,
    copyBuffer: null,
    rootnode: null,
    loaddialog: false,
    dialogtab: 0,
  },
  getters: {
  },
  mutations: {
    fetchHotspots (state, json) {
      state.hotspots = json
    },
    setLoaddialog (state, loaddialog) {
      state.loaddialog = loaddialog
    },
    setDialogtab (state, dialogtab) {
      state.dialogtab = dialogtab
    },
    setCopyBuffer (state, copyBuffer) {
      state.copyBuffer = copyBuffer
    },
    setRootnode (state, rootnode) {
      state.rootnode = rootnode
    },
    setNextCursor (state, nextCursor) {
      state.nextCursor = nextCursor
    },
    setContainersize (state, containerSize) {
      state.containerSize = containerSize
    },
    setSceneLoaded (state, sceneLoaded) {
      state.sceneLoaded = sceneLoaded
    },
    setPlayergui (state, playergui) {
      state.playergui = playergui
    },
    setIsLoading (state, isLoading) {
      state.isLoading = isLoading
    },
    setPlayervignette (state, playervignette) {
      state.playervignette = playervignette
    },
    setPlayercameraconstraints (state, playercameraconstraints) {
      state.playercameraconstraints = playercameraconstraints
    },
    setAspectratio (state, aspectratio) {
      state.aspectratio = aspectratio
    },
    setProjectsdialog (state, projectsdialog) {
      state.projectsdialog = projectsdialog
    },
    setSceneuid (state, sceneuid) {
      state.sceneuid = sceneuid
    },
    setDuration (state, duration) {
      state.duration = duration
    },
    setAnimationSection (state, section) {
      state.animationsection = section
    }
  },
  actions: {
  },
  modules: {
    time,
    camera,
    capture,
    ui,
  },
  strict: true
})
