<script>
import time from '../tools/time'

export default {
  name: 'viewer-overlay',
  components: {
  },
  props: {
  },
  data: () => ({
    currenttime: 0
  }),
  computed: {
    embed() {
      return this.$store.state.ui.embed
    },
    scroll() {
      return this.$store.state.ui.scroll
    },
    fps() {
      return this.$store.state.time.fps
    },
    cameras() {
      return this.$store.state.camera.all
    },
    maxtime() {
      return this.cameras[this.cameras.length-1]?.time
    },
    thiscamera: {
      get: function() {
        return this.$store.state.camera.thiscamera
      },
      set: function(newValue) {
        const camera = this.cameras[newValue]
        this.$store.commit('camera/setSelectedcamera', camera.id)
        this.$store.commit('time/setCurrentframe', camera.time * this.fps)
        time.setTime(camera.time)
        this.$root.$refs.drawer.setPlayhead(camera.time * this.fps)
      }      
    },
    sliderMax() {
      return this.cameras.length - 1
    },
    sequencePlaying() {
      return this.$store.state.time.sequencePlaying
    },
    sceneuid() {
      return this.$store.state.sceneuid
    },
    allowNavigation() {
      return {
        'pointer-events': this.$store.state.ui.blockNavigation || this.$store.state.ui.scroll ? 'all': 'none'
      }
    }
  },
  mounted: function () {
    // this.maxtime = this.cameras[this.cameras.length-1]?.time
  },
  methods: {
    toggleAnimation() {
      if (this.sequencePlaying) {
        this.$store.commit('time/setSequencePlaying', false)
      } else {
        time.setupAnimation(0)
        this.$root.$refs.drawer.animateCamera(0)
      }
    },
    loadInCinemafab() {

      const sequence = btoa(JSON.stringify(this.cameras))
      const url = `${window.location.origin}?sceneuid=${this.sceneuid}&sequence=${sequence}`
      window.open( url, "_blank");
      // navigator.clipboard.writeText(url);
      // this.snackbar = true      
    },
    onScroll (e) {
      this.currenttime = this.maxtime * (e.target.scrollTop / 2000)
      if (!this.$store.state.time.sequencePlaying) {
        time.setTime(this.currenttime)
        this.$store.commit('time/setCurrentframe', this.currenttime * this.fps)
        this.$root.$refs.drawer.setPlayhead()
      }      
    },
  }
}
</script>

<template>
  <div 
    v-if="embed" 
    class="viewerWrapper"
    v-bind:style="allowNavigation"
  >
    <div
      v-if="scroll"
      v-scroll.self="onScroll"
      class="scrollwrapper"
    >
      <div
        class="scrollable"
      >
      </div>
    </div>

    <v-sheet
      class="sheet d-flex align-center pa-2 pl-14 mx-auto"
      :width="$vuetify.breakpoint.sm ? '100%' : '40%'"
      color="transparent"

    >
      <v-btn
        class="mr-2"
        @click="loadInCinemafab"
        fab
        dark
        color="pink darken-3"
      >
        <v-icon large>mdi-movie-open</v-icon>
      </v-btn>      
      <v-btn
        @click="toggleAnimation"
        fab
        dark
        color="pink darken-3"
        class="mr-7"
      >
      <v-icon v-if="!sequencePlaying" large>
          mdi-play
        </v-icon>
        <v-icon v-else large>
          mdi-stop
        </v-icon>  
      </v-btn>         
      <v-slider
        v-if="sliderMax > 0"
        :max="sliderMax"
        v-model="thiscamera"
        ticks="always"
        tick-size="4"
        hide-details
        color="pink darken-3"
      ></v-slider>
    </v-sheet>
  </div>
</template>

<style lang="scss" scoped>
.viewerWrapper {
  overflow-y: hidden;
  position: absolute;
  top: 0px;
  left: 0px;  
  z-index:3;
  width: 100%;
  height: 100%;
}
.scrollwrapper {
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  left: 0px;  
  z-index:2;
  width: 100%;
  height: 100%;
}
.scrollable {
  width: 100%;
  height: calc(2000px + 100vh);
}
.sheet {
  position: absolute;
  bottom: 0px;
  pointer-events: all;
  z-index:3;
}
</style>
