<script>


export default {
  name: 'viewer-settings',
  components: {

  },
  props: {
  },
  data: function () {
    return {
      navigationoptions: [
        { title: 'Yes, after sequence has played once', value: 1 },
        { title: 'No', value: 0},
      ],
      navigation: 1,
      playsequenceoptions: [
        { title: 'Yes, once', value: 1 },
        { title: 'Yes, in a loop', value: 2 },
        { title: 'No', value: 0},
      ],
      playsequence: 1,
      playbutton: true,
      cinemafabbutton: true,
      timelineslider: true,
      timelinescroll: false,
    }
  },  
  computed: {
    background() {
      return this.$store.state.ui.background
    },
    sceneuid() {
      return this.$store.state.sceneuid
    },
    cameras() {
      return this.$store.state.camera.all
    },
  },
  mounted: function () {
  },
  methods: {
    shareViewerUrl() {
      const sequence = btoa(JSON.stringify(this.cameras))
      let url = `${window.location.origin}?viewer=1&navigation=${this.navigation}&play=${this.playsequence}&scroll=${this.timelinescroll}&sceneuid=${this.sceneuid}&sequence=${sequence}`
      if (this.background) {
        url += `&background=${this.background.substr(1)}`
      }
      navigator.clipboard.writeText(url);
      this.$emit('snackbar')
    },
  }
}
</script>

<template>
  <v-sheet>
    <v-toolbar flat color="cardaccent">
      <v-toolbar-title class="text-h6">Cinematic viewer settings</v-toolbar-title>
    </v-toolbar>
    <div
      class="d-flex flex-column"
    >
      <v-toolbar
        width="100%"
        dense
        flat
        class="pa-0 pr-2"
      >
        <v-sheet width="200" class="ml-3 text-subtitle-1">
          Allow 3D navigation
        </v-sheet>            
        <v-spacer></v-spacer>
        <v-sheet width="400">
          <v-select
            class="mt-0"
            item-text="title"
            item-value="value"
            single-line
            hide-details="true"
            :items="navigationoptions"
            v-model="navigation"
          ></v-select>
        </v-sheet>
      </v-toolbar>
      <v-toolbar
        width="100%"
        dense
        flat
        class="pa-0 pr-2"
      >
        <v-sheet width="200" class="ml-3 text-subtitle-1">
          Play camera sequence
        </v-sheet>            
        <v-spacer></v-spacer>
        <v-sheet width="400">
          <v-select
            class="mt-0"
            item-text="title"
            item-value="value"
            single-line
            hide-details="true"
            :items="playsequenceoptions"
            v-model="playsequence"
          ></v-select>
        </v-sheet>
      </v-toolbar>
      <v-toolbar
        width="100%"
        dense
        flat
        class="pa-0 pr-2"
      >
        <v-sheet width="200" class="ml-3 text-subtitle-1">
          Play/Stop button
        </v-sheet>            
        <v-spacer></v-spacer>
        <v-sheet width="400">
          <v-switch
            disabled
            class="mt-0"
            single-line
            hide-details="true"
            v-model="playbutton"
          ></v-switch>
        </v-sheet>
      </v-toolbar>
      <v-toolbar
        width="100%"
        dense
        flat
        class="pa-0 pr-2"
      >
        <v-sheet width="200" class="ml-3 text-subtitle-1">
          Cinemafab button
        </v-sheet>            
        <v-spacer></v-spacer>
        <v-sheet width="400">
          <v-switch
            disabled
            class="mt-0"
            single-line
            hide-details="true"
            v-model="cinemafabbutton"
          ></v-switch>
        </v-sheet>
      </v-toolbar>
      <v-toolbar
        width="100%"
        dense
        flat
        class="pa-0 pr-2"
      >
        <v-sheet width="200" class="ml-3 text-subtitle-1">
          Timeline slider
        </v-sheet>            
        <v-spacer></v-spacer>
        <v-sheet width="400">
          <v-switch
            disabled
            class="mt-0"
            single-line
            hide-details="true"
            v-model="timelineslider"
          ></v-switch>
        </v-sheet>
      </v-toolbar>
      <v-toolbar
        width="100%"
        dense
        flat
        class="pa-0 pr-2"
      >
        <v-sheet width="200" class="ml-3 text-subtitle-1">
          Timeline scroll
        </v-sheet>            
        <v-spacer></v-spacer>
        <v-sheet width="400">
          <v-switch
            class="mt-0"
            single-line
            hide-details="true"
            v-model="timelinescroll"
          ></v-switch>
        </v-sheet>
      </v-toolbar>
      
      <v-btn
        @click="shareViewerUrl"
        outlined
        class="my-2"
      >
        Copy viewer URL
      </v-btn>
      <div>
        The viewer will play your camera sequence, without all of the editing options. This is great for showcasing your 3D models.
      </div>
    </div>
  </v-sheet>
    
</template>

<style lang="scss" scoped>
</style>
