const state = {
  doCapture: false,
  rendersize: {
    x: 512,
    y: 512
  },
  type: 'zip'
}

const getters = {
}

const actions = {
}

const mutations = {
  setRenderSize (state, rendersize) {
    state.rendersize = rendersize
  },
  setDoCapture (state, doCapture) {
    state.doCapture = doCapture
  },
  setCapturetype (state, type) {
    state.type = type
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
