import Sketchfab from "@sketchfab/viewer-api";
import store from '../../store'
import postprocessing from './postprocessing'
import background from './background'
import camera from "./camera";

let api;

const init = function(elementId) {
  const iframe = document.getElementById(elementId);
  const version = "1.12.0";
  return new Sketchfab(version, iframe);
};

const loadScene = function(elementId, sceneId) {
  store.commit('setRootnode', null)
  store.commit('camera/clearCamera')

  init(elementId)
  const playersettings = getPlayerSettings()
  console.log("playersettings", playersettings);
  return new Promise((resolve, reject) => {
    store.commit('setIsLoading', true)

    let client = init("player__iframe");

    client.init(sceneId, {
      success: function onSuccess(theApi) {
        console.log("Client success");
        window.api = api = theApi;
        api.load();
        api.start();
        api.addEventListener(
          'click',
          function (info) {
            console.log('Mouseclick', info);
          },
          { pick: 'fast' }
        );
        api.addEventListener('camerastart', function () {
          store.commit('camera/setCameraMoves', true)
        });
        api.addEventListener('camerastop', function () {
          store.commit('camera/setCameraMoves', false)
        });                
        api.addEventListener("viewerready", function() {
          console.log("Viewer ready");
          store.commit('setSceneLoaded', true)
          store.commit('setIsLoading', false)

          const container = document.getElementById('ratio_container');
          store.commit('setContainersize', { x: container.clientWidth, y: container.clientHeight })
          
          camera.setCameraConstraints(false)
          .then(() => {
            store.commit('setPlayercameraconstraints', false)
          })

          postprocessing.getPostProcessing()
          .then(settings => {
            store.commit('setPlayervignette', settings.vignetteEnable)
          })

          const bkg = store.state.ui.background
          if (bkg) background.setBackgroundHex(bkg)

          getRootnode()
          .then(() => {
            resolve(api);
          })
          
        });
      },
      error: function(error) {
        console.log(error);
        reject(error)
      },
      ...playersettings
    });
  });
};

/**
 * The rootnode of the Sketchfab scene is used to perform the Roll action. The camera isn't rolled,
 * but the scene is rotated to simulate the camera roll. 
 * @returns a promise
 */
const getRootnode = function () {
  return new Promise(resolve => {
    api.getRootMatrixNode(function (err, instanceID) {
      if (err) {
        console.log(err.stack)
      } else {
        store.commit('setRootnode', {id: instanceID, matrix: null})
        api.getMatrix(instanceID, function(err, rootMatrix) {
          if (err) {
            console.log(err.stack)
          } else {
            store.commit('setRootnode', {id: instanceID, matrix: rootMatrix.local})
            resolve()
          }
        })
      }
    })
  })
}

const getPlayerSettings = function () {
  const playergui = store.state.playergui
  const playersettings = playergui ? store.state.playersettings.untouched : store.state.playersettings.bare
  return playersettings
}

export default {
  get api() {
    return api;
  },
  init,
  loadScene
};
