<template>
  <v-navigation-drawer 
    app
    width="600"
    clipped
    permanent
    v-if="!embed && sceneLoaded"
  >
    <v-card
      flat
      tile
      class="ma-0"
      color="transparent"
    >
      <v-sheet tile class="d-flex align-center pt-4 px-2">
        <v-btn
          @click="playAnimation"
          fab
          dark
          color="pink darken-3"
          class="mb-4 mr-4"
        >
          <v-icon large>
            mdi-play
          </v-icon>  
        </v-btn>    

        <v-btn
          @click="recordAnimation"
          fab
          dark
          color="pink darken-3"
          class="mb-4 mr-4"
        >
          <v-icon large>
            mdi-camera
          </v-icon>  
        </v-btn>    

        <v-btn
          @click="stopAnimation"
          fab
          dark
          color="pink darken-3"
          class="mb-4 mr-4"

        >
          <v-icon large>
            mdi-stop
          </v-icon>  
        </v-btn>

        <v-slider
          class="mt-8 mx-4 mb-n4"
          v-model="currenttime"
          @input="movePlayerSlider"
          :max="duration"
          step=0.1
          thumb-label="always"
          color="pink darken-3"
        >
          <template v-slot:append>
            <v-text-field
              class="mt-n8"
              v-model="duration"
              label="max time"
              type="number"
              min="1"
              max="120"
            ></v-text-field> 
          </template> 
        </v-slider>  
      </v-sheet>

      <div class="text-h5 font-weight-medium ml-4">Camera list</div>

      <v-divider></v-divider>

      <CameraLister 
        @setCamera="selectCamera"
        @playCamera="playCamera"
        class="px-4"
      />

      <v-sheet class="mx-4">
        <v-btn
          @click="getCamera"
          depressed
          color="pink darken-4"
          dark
          tile
          block
          class="mb-6"
        >
          <v-icon left>
            mdi-camera-outline
          </v-icon>      
          Add camera
        </v-btn>          
        <div 
          v-if="cameras.length === 0"
          class="text-body font-weight-bold"
        >
          Add a <v-icon>mdi-camera-outline</v-icon> camera to get started
        </div>            
        <div
          v-if="cameras.length > 0"
          class="text-body font-weight-light"
        >
          <v-icon>mdi-camera-retake-outline</v-icon>: update the camera. <v-icon>mdi-radiobox-blank</v-icon>: view the camera. <v-icon>mdi-delete</v-icon>: delete the camera
        </div>
      </v-sheet>
      
    </v-card>

    <template v-slot:append>
      <v-sheet class="ma-2" color="transparent">
        <div class="text-h5 font-weight-medium ml-4">Camera Properties</div>
        <CameraProperties 
          @setCamera="selectCamera"
        />
        <v-divider></v-divider>


        <v-divider></v-divider>

        <v-sheet
          class="d-flex align-center commercialbar mt-1"
          color="transparent"
        >
          <v-avatar
            @click.stop="openPortfolio()"
          >
            <v-img src="../assets/img/Klaas_profilePicture.jpg"></v-img>      
          </v-avatar>

          <div 
            class="d-flex flex-column ml-2 mr-auto"
            @click.stop="openPortfolio()"
          >
            <div 
              class="text-truncate text-h5 font-weight-medium"
            >Klaas Nienhuis</div>
          
            <div 
              class="mt-n1 text-truncate text-subtitle-2 font-weight-light text--secondary text-uppercase"
            >3d development</div>
          </div>

          <v-btn
            fab
            small
            color="primary" 
            @click.stop="openPortfolio()"
          >
            <v-icon>mdi-emoticon-outline</v-icon>
          </v-btn>
          <v-btn
            fab
            dark
            small
            color="primary" 
            href="https://klaasnienhuis.freshsales.io/web_forms/404bcc64bc2b7d2fadc81f06d07264e35c21a9cc6c36e680a1bd52d8b929b501/form.html" 
            target="_blank"
            class="ml-2"
          >
            <v-icon>mdi-at</v-icon>
          </v-btn>

        </v-sheet>
      </v-sheet>
    </template>


  </v-navigation-drawer>
</template>

<script>

import CameraLister from './camera-lister.vue';
import CameraProperties from './camera-properties';
import camera from '@/tools/sketchfab/camera';
import time from '@/tools/time'

export default {
  name: "nav-drawer",
  components: {
    CameraLister,
    CameraProperties,
  },
  data: () => ({
    // animating: false,
    noiseSpeed: 0.01,
    noiseIntensity: 0.2,
    noiseEnable: false,
  }),
  computed: {
    cameras() {
      return this.$store.state.camera.all
    },
    thiscamera() {
      return this.$store.state.camera.thiscamera
    },
    nextcamera() {
      return this.$store.state.camera.nextcamera
    },
    length() {
      return this.$store.state.time.length
    },
    fps() {
      return this.$store.state.time.fps
    },
    selectedcamera() {
      return this.$store.state.camera.currentid
    },
    lastcamera() {
      return this.cameras[this.cameras.length - 1]
    },
    sequencePlaying() {
      return this.$store.state.time.sequencePlaying
    },
    currenttime: {
      get: function() {
        return this.$store.state.time.currenttime
      },
      set: function(newValue) {
        this.$store.commit('time/setCurrenttime', newValue)
      }
    },
    embed() {
      return this.$store.state.ui.embed
    },
    duration: {
      get: function() {
        return this.$store.state.duration
      },
      set: function(newValue) {
        this.$store.commit('setDuration', parseInt(newValue))
      },
    },
    sceneLoaded() {
      return this.$store.state.sceneLoaded
    },
    hasRoll() {
      return this.$store.state.rootnode !== null
    },
  },
  mounted () {
  },
  created () {
    this.$root.$refs.drawer = this
  },
  methods: {
    movePlayerSlider() {
      if (!this.$store.state.time.sequencePlaying) {
        time.setTime(this.currenttime)
        this.$store.commit('time/setCurrentframe', this.currenttime * this.fps)
        this.setPlayhead()
      }
    },
    selectCamera(camera) {
      this.currenttime = camera.time
      // this.$store.commit('time/setCurrenttime', camera.time)
      this.$store.commit('time/setCurrentframe', camera.time * this.fps)
      time.setTime(camera.time)
      this.setPlayhead(this.currenttime * this.fps)

      this.$store.commit('camera/setSelectedcamera', camera.id)
    },
    async getCamera() {
      const cam = await camera.captureCamera()
      this.$store.commit('camera/addCamera', cam)
    },
    later(delay) {
      return new Promise(function(resolve) {
        setTimeout(resolve, delay);
      });
    },
    setPlayhead() {
      // this.currenttime = this.$store.state.time.currentframe / this.fps
      this.$store.commit('time/setCurrenttime', this.$store.state.time.currentframe / this.fps)

      const [pos, target, fov] = time.getCameraTransform(
        this.cameras[this.thiscamera],
        this.cameras[this.nextcamera],
        this.length,
        {enable: this.noiseEnable, speed: this.noiseSpeed, intensity: this.noiseIntensity},
        {enable: this.hasRoll && this.$store.state.rootnode.matrix !== null, instanceId: this.$store.state.rootnode.id}
      )
      this.$store.commit('camera/setSequenceData', {camera: pos, target: target, fov: fov})

      camera.setCamera(pos, target, fov)
    },
    tick () {
      this.setPlayhead()
      time.stepAnimation(
        this.length,
        this.tick
      )
    },    
    animateCamera(startCameraID) {
      console.log("startCameraID", startCameraID);
      const frame = time.setupAnimation(startCameraID)
      this.$store.commit('time/setCurrentframe', frame)
      this.tick()
    },
    playAnimation() {
      this.$store.commit('time/setSequencePlaying', true)
      this.$store.commit('camera/clearSequenceData')
      time.setTime(0)
      this.animateCamera(0)
    },
    recordAnimation() {
      this.$store.commit('capture/setDoCapture', true)


      this.playAnimation()
    },
    playCamera(cam) {
      const idx = this.cameras.findIndex(item => item.id === cam.id)
      this.animateCamera(idx)
    },
    stopAnimation() {
      this.$store.commit('time/setSequencePlaying', false)
    },
    openPortfolio () {
      this.$store.commit('setProjectsdialog', true)
    },
  }
};
</script>

<style lang="scss" scoped>

</style>