const state = {
  currenttime: 0,
  currentframe: 0,
  segmentframe: 0,
  sequencePlaying: false,
  length: 0,
  fps: 60,
  loopAmount: null,
  loopCount: 0,
  play: 0
}

const getters = {
  getNextCamera: (state) => (time) => {
    if (!state.all[1]) return null
    let nextCam = state.all.find(cam => cam.time > time)
    if (nextCam) {
      return state.all.findIndex(cam => cam.id === nextCam.id)
    } else return null
  },
}

const actions = {
}

const mutations = {
  setSequencePlaying (state, sequencePlaying) {
    state.sequencePlaying = sequencePlaying
  },
  setCurrenttime (state, currenttime) {
    state.currenttime = currenttime
  },
  setSegmentframe (state, segmentframe) {
    state.segmentframe = segmentframe
  },
  setCurrentframe (state, currentframe) {
    state.currentframe = currentframe
  },
  incrementCurrentframe (state) {
    state.currentframe++
  },
  setLength (state, length) {
    state.length = length
  },
  setLoopAmount (state, loopAmount) {
    state.loopAmount = loopAmount
  },
  incrementLoopCount (state) {
    state.loopCount += 1
  },
  setPlay (state, play) {
    state.play = play
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
