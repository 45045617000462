<template>
  <v-container 
    class="fill-height justify-center" 
    fluid 
    ref="container"
    id="ratio_container"
    :style="containerheight"
    v-resize="onResize"
    style="background-color: #00000000"
    @keyup="iframeKeyup"
  >
    <DialogCommercial />
    <SelectionSheet />
    <!-- <AnnotationOverlay v-else :cameramoves='cameramoves' />     -->
    <ViewerOverlay />
    <iframe
      id="player__iframe"
      @load="onIframeLoaded"
      class="player"
      :style="dimensions"
      
    />

  </v-container>
</template>

<script>
// import scenetools from '../tools/scene'
// import environment from '../tools/environment'
import player from '../tools/sketchfab/player'
import time from '../tools/time'
// import postprocessing from '../tools/sketchfab/postprocessing'
// import AnnotationOverlay from './annotation-overlay.vue'
import ViewerOverlay from './viewer-overlay.vue'
import DialogCommercial from './dialog-commercial'
import SelectionSheet from './selection-sheet'
import axios from 'axios'

export default {
  name: "sketchfab-player",
  components: {
    // AnnotationOverlay,
    DialogCommercial,
    SelectionSheet,
    ViewerOverlay,
  },
  data: () => ({
    iframeLoaded: false,
    // sceneLoaded: false,
    // sceneuid: 'dGUrytaktlDeNudCEGKk31oTJY',
    playersettings: {
      autospin: 0.0,
      preload: 0,
      ui_stop: 0,
      ui_infos: 0, // need biz account
      ui_controls: 0, // need biz account
      ui_watermark: 0,
      scrollwheel: 1,
      graph_optimizer: 0,
      merge_materials: 0,
      transparent: 0,
      dof_circle: 0,
    },
    playersettings_gui: {
      autospin: 0.0,
      preload: 0,
      // ui_stop: 0,
      // ui_infos: 0, // need biz account
      // ui_controls: 0, // need biz account
      // ui_watermark: 0,
      // scrollwheel: 1,
      // graph_optimizer: 0,
      // merge_materials: 0,
      // transparent: 0,
      // dof_circle: 0,
    },    
    // containerSize: {
    //   x: 0,
    //   y: 0
    // },
    // containerWidth: 0
  }),
  computed: {
    models() {
      return this.$store.state.models
    },
    sceneLoaded() {
      return this.$store.state.sceneLoaded
    },
    loading() {
      return this.$store.state.loading
    },
    containerSize() {
      return this.$store.state.containerSize
    },
    shareSheet() {
      return this.$store.state.shareSheet
    },
    loadComplete() {
      // return false
      return this.iframeLoaded && this.sceneLoaded
    },
    dimensions() {
      let w = this.containerSize.x
      let h = this.containerSize.y
      //square
      // if (this.$store.state.aspectratio > 1) {
      //   // portrait
      // } else {
        // landscape
      if (this.$store.state.aspectratio !== null) {

        if (this.containerSize.y / this.containerSize.x > this.$store.state.aspectratio) {
          w = this.containerSize.x
          h = this.containerSize.x * this.$store.state.aspectratio
        } else {
          w = this.containerSize.y / this.$store.state.aspectratio
          h = this.containerSize.y
        }
      }
      // }
      return {
        width: `${w}px`,
        height: `${h}px`,
      }
    },
    containerheight() {
      if (this.embed) {
        return 'height: 100vh;'
      } else {
        return `height: calc(100vh - 112px)`
      }
    },
    cameramoves() {
      return this.$store.state.camera.cameramoves
    },
    embed() {
      return this.$store.state.ui.embed
    },
    playergui() {
      return this.$store.state.playergui
    },
    // playervignette() {
    //   return this.$store.state.playervignette
    // },
  },
  watch: {
    playergui(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);
      this.$store.commit('setSceneLoaded', false)
      player.loadScene('player__iframe', this.$store.state.sceneuid)
    },
  },  
  async mounted () {
    // get sketchfab id from query
    this.onResize()
    const query = Object.assign({}, this.$route.query)
    console.log("query", query);
    if (query.sceneuid) {
      this.$store.commit('setSceneuid', query.sceneuid)
    }

    if (query.embed) {
      this.$store.commit('ui/setEmbed', query.embed === '1')
      if (query.embed === '1') {
        this.$root.$refs.drawer.animateCamera()
      }
    }
    
    if (query.viewer) {
      console.log('VIEWER BABY')
      this.$store.commit('ui/setEmbed', query.viewer === '1')
    }
    if (query.loopamount) {
      this.$store.commit('time/setLoopAmount', parseInt(query.loopamount))
    }
    if (query.play) {
      // 0: don't play the sequence
      // 1: play the sequence once
      // 2: loop the sequence indefinitely
      this.$store.commit('time/setPlay', parseInt(query.play))
    }
    if (query.navigation) {
      // 0: don't allow navigation
      // 1: after sequence has played at least once
      this.$store.commit('ui/setNavigation', parseInt(query.navigation))
    }
    if (query.scroll === 'true') {
      this.$store.commit('ui/setScroll', query.scroll === 'true')
    }
    if (query.background) {
      this.$store.commit('ui/setBackground', `#${query.background}`)
    }

    const json = await this.getJsonFile('./data/tell_maison.json')
    this.$store.commit('fetchHotspots', json.data.hotspots)
    
    player.loadScene('player__iframe', this.$store.state.sceneuid)
    .then(() => {
      if (query.sequence) {
        let data = atob(query.sequence)
        data = JSON.parse(data)
        if (data.length > 0) {
          this.$store.commit('camera/clearCamera')
          data.forEach(cam => {
            this.$store.commit('camera/addCamera', cam)
          })

          if (this.$store.state.ui.embed && this.$store.state.time.play > 0) {
            console.log('BOOYAH')
            // Activate the first camera
            time.setupAnimation(0)
            this.$root.$refs.drawer.animateCamera(0)
          }          
        }
      }
    })
  },
  methods: {
    onResize () {
      const container = document.getElementById('ratio_container');
      this.$store.commit('setContainersize', { x: container.clientWidth, y: container.clientHeight })
    },
    onIframeLoaded() {
      this.iframeLoaded = true
    },
    getJsonFile (url) {
      const config = {
        method: "get",
        url: url,
        headers: {},
      };
      return axios(config)
    },
    iframeKeyup(event) {
      console.log("event", event);
    }
  }
};
</script>

<style lang="scss" scoped>
.fill-height {
  padding:0px;
}

.player {
  width: 100%;
  height: 100%;
  pointer-events: all;
  outline: none;
  border: 0;
}
</style>