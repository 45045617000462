<script>


export default {
  name: 'capture-settings',
  components: {

  },
  props: {
  },
  data: function () {
    return {
      resolutions: [
        { title: '1080p', size: [1920, 1080] },
        { title: '720p', size: [1280, 720] },
      ],
      capturetypes: [
        { title: 'Zipped PNG\'s', type: 'zip' },
        { title: 'Webm video', type: 'webm' },
        // { title: 'MP4 video', type: 'mp4' },
      ],
      width: 0,
      height: 0,
      type: ''
    }
  },  
  computed: {

    tab: {
      get () {
        return this.$store.state.dialogtab
      },
      set (newValue) {
        this.$store.commit('setDialogtab', newValue)
      }
    },
  },
  mounted: function () {
    this.width = this.$store.state.capture.rendersize.x
    this.height = this.$store.state.capture.rendersize.y
    this.type = this.$store.state.capture.type
  },
  methods: {
    setCommonResolution(item) {
      console.log("item", item);
      this.width = item[0]
      this.height = item[1]
      this.commitSize()
    },
    setCapturetype(item) {
      this.$store.commit('capture/setCapturetype', item)
    },
    commitSize() {
      this.$store.commit('capture/setRenderSize', {x: this.width, y: this.height})
    },
    commitWidth(width) {
      this.$store.commit('capture/setRenderSize', {x: parseInt(width), y: this.height})
    },
    commitHeight(height) {
      this.$store.commit('capture/setRenderSize', {x: this.width, y: parseInt(height)})
    },
  }
}
</script>

<template>
  <v-sheet>
    <v-toolbar flat color="cardaccent">
      <v-toolbar-title class="text-h6">Capture settings</v-toolbar-title>
    </v-toolbar>
    <v-select
      :items="resolutions"
      item-text="title"
      item-value="size"
      @change="setCommonResolution"
      label="Common resolutions"
      hide-details="true"
      filled
    >
    </v-select>

    <v-text-field
      v-model="width"
      @input="commitWidth"
      hide-details="true"
      label="Capture width"
      filled
      type="number"
      min="64"
      max="4096"
    ></v-text-field>   
       
    <v-text-field
      v-model="height"
      @input="commitHeight"
      hide-details="true"
      label="Capture height"
      filled
      type="number"
      min="64"
      max="4096"
    ></v-text-field>   

    <v-select
      v-model="type"
      :items="capturetypes"
      item-text="title"
      item-value="type"
      @change="setCapturetype"
      label="Capture type"
      hide-details="true"
      filled
    >
    </v-select>
  </v-sheet>
    
</template>

<style lang="scss" scoped>
</style>
