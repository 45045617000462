import { vec3, mat4, quat, glMatrix } from 'gl-matrix'
import store from '../../store'
import player from "./player";

const easeInSine = function(x) {
  return 1 - Math.cos((x * Math.PI) / 2);
}

const easeLinear = function(x) {
  return x
}

const easeInOutSine = function(x) {
  return -(Math.cos(x * Math.PI) - 1)/ 2;
}

const easeInOutQuad = function(x) {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

const easeInQuad = function(x) {
  return x * x;
}

const easeInCubic = function(x) {
  return x * x * x;
}

const easeOutCubic = function(x) {
  return 1 - Math.pow(1 - x, 3);
}

const easeInExpo = function(x) {
  return x === 0 ? 0 : Math.pow(2, 10 * x - 10);
}

const easeOutExpo = function(x) {
  return x === 1 ? 1 : 1 - Math.pow(2, -10 * x);
}

// const easeVertigo = function(x) {
//   return x * x * x;
// }

const interpolatePosition = function (start, end, amount, easing) {

  return [
    interpolateValue(start[0], end[0], amount, easing),
    interpolateValue(start[1], end[1], amount, easing),
    interpolateValue(start[2], end[2], amount, easing),
    // (1 - easeInOutSine(amount)) * start[0] + easeInOutSine(amount) * end[0],
    // (1 - easeInOutSine(amount)) * start[1] + easeInOutSine(amount) * end[1],
    // (1 - easeInOutSine(amount)) * start[2] + easeInOutSine(amount) * end[2],
  ]
  // return [
  //   (1 - amount) * start[0] + amount * end[0],
  //   (1 - amount) * start[1] + amount * end[1],
  //   (1 - amount) * start[2] + amount * end[2],
  // ]
}

const interpolateLatlon = function (start, end, amount, easing, spin) {
  // make sure that we're turning along the fastest longitude
  let endlon = end[1]
  if (end[1] - start[1] > 180) endlon =  end[1] - 360
  if (end[1] - start[1] < -180) endlon =  end[1] + 360
  if (spin !== 0) {
    endlon += spin * 360
  }


  let lat = interpolateValue(start[0], end[0], amount, easing)
  let lon = interpolateValue(start[1], endlon, amount, easing)

  return [
    lat,
    lon,
  ]
}

const interpolateValue = function (start, end, amount, easing) {
  let easingFunction = easeLinear
  if (easing === 'linear') easingFunction = easeLinear
  if (easing === 'slomo') easingFunction = easeLinear
  if (easing === 'sine') easingFunction = easeInOutSine
  if (easing === 'quad') easingFunction = easeInOutQuad
  if (easing === 'cubic') easingFunction = easeInCubic
  if (easing === 'exponential') easingFunction = easeInExpo
  if (easing === 'vertigo') {
    easingFunction = start < end ? easeInExpo : easeOutExpo
  }
  if (easing === 'exponentialOut') easingFunction = easeOutExpo
  


  return (1 - easingFunction(amount)) * start + easingFunction(amount) * end
}

const getVertigoWidth = function (radius, fov) {
  const fovRad = fov * (Math.PI / 180)
  return 2 * radius * Math.tan(0.5 * fovRad)
}

/**
 * For vertigo, the fov is a function of the distance and a width.
 * Ideally, the width is the same at the start and the end. It represents the size
 * of the object we're viewing at the distance
 * @param {number} startR Start radius
 * @param {number} endR End radius
 * @param {number} width The width of the fov
 * @param {number} amount The progress of the easing
 * @returns a fov angle
 */
const interpolateFov = function (startR, endR, width, amount) {
  let easing = "exponential"
  if (startR > endR) easing = 'exponentialOut'
  const R = interpolateValue(startR, endR, amount, easing)
  const fovRad = (2 * Math.atan(width/(2*R)))
  return fovRad / (Math.PI/180)
}

const quatToSpherical = function(quat){
  // Convert a quat to lat lon values
  let look = vec3.fromValues(0, 0, 1)
  vec3.transformQuat(look, look, quat)
  vec3.normalize(look, look)
  if(Math.abs(look[0]) < 0.001) look[0] = 0.001;

  var angles = {};
  var radius = 1;

  angles.lat = Math.acos(look[2]/radius);
  angles.lon = Math.atan2(look[1] , look[0]);

  return angles;
}

const getLatLonFromPos = function (sf_pos, sf_target) {
  const pos = vec3.fromValues(-sf_pos[1], sf_pos[0], -sf_pos[2])
  const target = vec3.fromValues(-sf_target[1], sf_target[0], -sf_target[2])

  const up = vec3.fromValues(0, 0, 1)
  const matrix = mat4.create()
  mat4.targetTo(matrix, pos, target, up)

  let q = quat.create()
  mat4.getRotation(q, matrix)
  let angles = quatToSpherical(q)
  // return angles
  const degAngles = [angles.lat * 180/Math.PI -90, angles.lon * 180/Math.PI + 180]
  return degAngles
}

/*
Convert a lat and lon value to a matrix. This assumes a certain radius
and an up vector. We're also assuming the target is at the origin
*/
const getPosFromLatLon = function (latlon, radius, target) {
  const lat = glMatrix.toRadian(latlon[0])
  const lon = glMatrix.toRadian(latlon[1])
  const R = radius ? radius : -1.5
  const origin = vec3.create()
  const T = target ? vec3.fromValues(target[0], target[1], target[2]) : vec3.create()
  const greenwich = vec3.fromValues(0, R, 0)
  vec3.rotateX(greenwich, greenwich, origin, lat)
  vec3.rotateZ(greenwich, greenwich, origin, lon)
  vec3.add(greenwich, greenwich, T)


  return [greenwich[0], greenwich[1], greenwich[2]]
}

const getRadius = function (sf_pos, sf_target) {
  const pos = vec3.fromValues(-sf_pos[1], sf_pos[0], -sf_pos[2])
  const target = vec3.fromValues(-sf_target[1], sf_target[0], -sf_target[2])
  return vec3.distance(pos, target)
}

const slomoRetimeAmount = function (amount, frame, length) {
  // retimes the amount. the first part moves really slow, the second
  // part accellerates
  const slomoEnd = 0.75
  const slomoProgress = 0.06
  const slomoLengthFactor = slomoEnd / slomoProgress
  const accelerateStart = 1 - (1 - slomoProgress) / (1 - slomoEnd)
  if (frame/(length - 1) < slomoEnd) {
    amount = frame/(length * slomoLengthFactor - 1)
    store.commit('setAnimationSection', 'a')
  } else {
    amount = interpolateValue(accelerateStart, 1, amount, 'linear')
    store.commit('setAnimationSection', 'b')
  }
  return amount
}

const matrixFromSfMatrix = function (sfMatrix) {
  if (sfMatrix[5] > 0 && sfMatrix[10] > 0) {
    // Z-up
    return mat4.fromValues(
      sfMatrix[0],
      sfMatrix[1],
      sfMatrix[2],
      sfMatrix[3],
      sfMatrix[4],
      sfMatrix[5],
      sfMatrix[6],
      sfMatrix[7],
      sfMatrix[8],
      sfMatrix[9],
      sfMatrix[10],
      sfMatrix[11],
      sfMatrix[12],
      sfMatrix[13],
      sfMatrix[14],
      sfMatrix[15]
    )     
  } else if (sfMatrix[6] > 0 && sfMatrix[9] < 0) {
    // Y-up
    return mat4.fromValues(
      sfMatrix[0],
      sfMatrix[1],
      sfMatrix[2],
      sfMatrix[3],
      -sfMatrix[8],
      -sfMatrix[9],
      -sfMatrix[10],
      -sfMatrix[11],
      sfMatrix[4],
      sfMatrix[5],
      sfMatrix[6],
      sfMatrix[7],
      sfMatrix[12],
      sfMatrix[13],
      sfMatrix[14],
      sfMatrix[15]
    ) 
  }
}

const rollNode = function (instanceID, angle, sf_pos, sf_target) {
  const rollRad = glMatrix.toRadian(angle)

  player.api.getMatrix(instanceID, function(err, rootMatrix) {
    if (err) console.log("err", err);
    // In some cases the rootnode does not have a matrix. Roll won't work
    // in those cases
    if (rootMatrix) {

    
      // console.log("A", rootMatrix.local);

      const pos = vec3.fromValues(sf_pos[0], sf_pos[1], sf_pos[2])
      const target = vec3.fromValues(sf_target[0], sf_target[1], sf_target[2])

      // console.log("T", target);
      const sf_rootMatrix = store.state.rootnode.matrix
      const rootPos = vec3.fromValues(sf_rootMatrix[12], sf_rootMatrix[13], sf_rootMatrix[14])
      
      let deltaRootTarget = vec3.create()
      vec3.sub(deltaRootTarget, target, rootPos)

      let cameraRotationAxis = vec3.create()
      vec3.sub(cameraRotationAxis, target, pos)

      const m4RootNode = matrixFromSfMatrix(sf_rootMatrix)

      mat4.translate(m4RootNode, m4RootNode, deltaRootTarget)

      const m4 = mat4.create()
      mat4.rotate(m4, m4RootNode, rollRad, cameraRotationAxis)
      vec3.negate(deltaRootTarget, deltaRootTarget)
      mat4.translate(m4, m4, deltaRootTarget)
      // const up = vec3.fromValues(0, 0, 1)
      // mat4.targetTo(m4, pos, target, up)
      // mat4.rotateZ(m4, m4, rollRad)
      // some nifty axis swap to get the rotation right
      if (sf_rootMatrix[5] !== 0) {
        // Z-up
        rootMatrix['local'][0] = m4[0]
        rootMatrix['local'][1] = m4[1]
        rootMatrix['local'][2] = m4[2]
        rootMatrix['local'][3] = m4[3]

        rootMatrix['local'][4] = m4[4]
        rootMatrix['local'][5] = m4[5]
        rootMatrix['local'][6] = m4[6]
        rootMatrix['local'][7] = m4[7]

        rootMatrix['local'][8] = m4[8]
        rootMatrix['local'][9] = m4[9]
        rootMatrix['local'][10] = m4[10]
        rootMatrix['local'][11] = m4[11]

        rootMatrix['local'][12] = m4[12]
        rootMatrix['local'][13] = m4[13]
        rootMatrix['local'][14] = m4[14]
        rootMatrix['local'][15] = m4[15]
      } else if (sf_rootMatrix[6] !== 0 && sf_rootMatrix[9] !== 0) {
        // Y-up
        rootMatrix['local'][0] = m4[0]
        rootMatrix['local'][1] = m4[1]
        rootMatrix['local'][2] = m4[2]
        rootMatrix['local'][3] = m4[3]
        
        rootMatrix['local'][4] = m4[8]
        rootMatrix['local'][5] = m4[9]
        rootMatrix['local'][6] = m4[10]
        rootMatrix['local'][7] = m4[11]
        
        rootMatrix['local'][8] = -m4[4]
        rootMatrix['local'][9] = -m4[5]
        rootMatrix['local'][10] = -m4[6]
        rootMatrix['local'][11] = -m4[7]
        
        rootMatrix['local'][12] = m4[12]
        rootMatrix['local'][13] = m4[13]
        rootMatrix['local'][14] = m4[14]
        rootMatrix['local'][15] = m4[15]
      }

      // console.log("B", rootMatrix.local);
      player.api.setMatrix(instanceID, rootMatrix.local, function(err2) {
        if (err2) console.log("err2", err2);
      })
    }
  })
}

export default {
  interpolatePosition,
  getLatLonFromPos,
  getPosFromLatLon,
  interpolateLatlon,
  interpolateValue,
  interpolateFov,
  getRadius,
  easeLinear,
  easeInOutSine,
  easeInSine,
  easeInOutQuad,
  easeInQuad,
  easeInCubic,
  easeInExpo,
  getVertigoWidth,
  easeOutCubic,
  slomoRetimeAmount,
  rollNode
}