import Vue from 'vue'
import Router from 'vue-router'
import Viewer from './views/Viewer.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'viewer',
      component: Viewer
    },
  ]
})
