<template>
  <v-app>
    <AppBar />
    <NavDrawer />
    <v-main>
      <router-view></router-view>
      
    </v-main>
  </v-app>
</template>

<script>
// import SketchfabPlayer from './components/sketchfab-player.vue'
import NavDrawer from './components/nav-drawer.vue'
import AppBar from './components/app-bar.vue'

export default {
  name: 'App',
  components: {
    // SketchfabPlayer,
    NavDrawer,
    AppBar
  }
}
</script>

<style>
</style>
