
const state = {
  all:[],
  embed: false,
  navigation: 1,
  blockNavigation: true,
  scroll: false,
  background: null,
  canroll: true
}

const getters = {
}

const actions = {
}

const mutations = {
  setEmbed (state, embed) {
    state.embed = embed
  },
  setNavigation (state, navigation) {
    state.navigation = navigation
  },
  setBlockNavigation (state, blockNavigation) {
    state.blockNavigation = blockNavigation
  },
  setScroll (state, scroll) {
    state.scroll = scroll
  },
  setBackground (state, background) {
    state.background = background
  },
  setCanroll (state, canroll) {
    state.canroll = canroll
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
