import animate from '@/tools/sketchfab/animate';
import camera from '@/tools/sketchfab/camera';
import screenshot from '@/tools/sketchfab/screenshot';
import noise from '@/tools/noise'
import store from '../store'
import ffmpegworker from './worker'

import JSZip from 'jszip';
// import CCapture from 'ccapture.js'
import { saveAs } from 'file-saver';


const getCameraTransform = function (thisCamera, nextCamera, segmentLength, noiseData, rollData) {

  const segmentframe = store.state.time.currentframe - thisCamera.time * store.state.time.fps
  store.commit('time/setSegmentframe', segmentframe)

  // modify time to slomo at first and accelerate later
  // this helps show off hotspots
  let amount = segmentframe/(segmentLength - 1)
  if (thisCamera.easing === 'slomo') {
    amount = animate.slomoRetimeAmount(amount, segmentframe, segmentLength)
  }

  const target = animate.interpolatePosition(thisCamera.target, nextCamera.target, amount, thisCamera.easing)
  let pos = [0,0,0]
  // RADIAL MOVEMENT
  if (thisCamera.motion === 'orbit') {
    const latlon = animate.interpolateLatlon(thisCamera.latlon, nextCamera.latlon, amount, thisCamera.easing, thisCamera.spin)
    if (thisCamera.circleRadius !== 0) {
          latlon[0] += Math.sin(Math.PI*2*amount * thisCamera.circleCount) * thisCamera.circleRadius 
        latlon[1] += Math.cos(Math.PI*2*amount * thisCamera.circleCount) * thisCamera.circleRadius - thisCamera.circleRadius
    }
    const radius = animate.interpolateValue(thisCamera.radius, nextCamera.radius, amount, thisCamera.easing)
    pos = animate.getPosFromLatLon(latlon, radius, target)
  } else if (thisCamera.motion === 'straight') {
    // LINEAR MOVEMENT
    pos = animate.interpolatePosition(thisCamera.position, nextCamera.position, amount, thisCamera.easing)
  }

  // ROLL
  if (rollData.enable) {
    const roll = animate.interpolateValue(thisCamera.roll, nextCamera.roll, amount, thisCamera.easing)
    animate.rollNode(rollData.instanceId, roll, pos, target)
  }

  let fov = 30
  if (thisCamera.easing === 'vertigo') {
    fov = animate.interpolateFov(thisCamera.radius, nextCamera.radius, nextCamera.width, amount)
  } else {
    fov = animate.interpolateValue(thisCamera.fov, nextCamera.fov, amount, thisCamera.easing)
  }


  if (noiseData.enable) {
    const n = noise.noise((store.state.time.currentframe + 50) * noiseData.speed)
    const noiseFactor = (n - 0.5) * noiseData.intensity
    pos = [
      pos[0] + noiseFactor,
      pos[1] - noiseFactor,
      pos[2] + noiseFactor,
    ]
  }
  
  return [pos, target, fov]
}

const setupAnimation = function (startCameraID) {
  if (store.state.camera.all.length > startCameraID) {
    store.commit('time/setSequencePlaying', true)
    // this.animating = true
    // Go to the starting position
    camera.setCamera(
      store.state.camera.all[startCameraID].fov,
      store.state.camera.all[startCameraID].position, 
      store.state.camera.all[startCameraID].target      
    )

    const currentframe = store.state.camera.all[startCameraID].time * store.state.time.fps
    store.commit('camera/setThiscamera', startCameraID)
    store.commit('camera/setNextcamera', startCameraID + 1)
    if (store.state.camera.all.length === 1 && startCameraID === 0) store.commit('camera/setNextcamera', 0)

    store.commit('camera/setSelectedcamera', store.state.camera.all[store.state.camera.thiscamera].id)
    store.commit('time/setLength', (store.state.camera.all[store.state.camera.nextcamera].time - store.state.camera.all[store.state.camera.thiscamera].time) * store.state.time.fps)
    if (store.state.camera.all.length === 1) store.commit('time/setLength', (store.state.duration * store.state.time.fps))
    

    return currentframe
  }
}

const captureFrame = async function() {
  if (store.state.capture.doCapture) {
    const data = await screenshot.getScreenshotPromise(store.state.capture.rendersize.x, store.state.capture.rendersize.y, false)

    if (store.state.capture.type === 'zip') {
      screenshot.addImageToZip(zip, data, store.state.time.currentframe.toString().padStart(4, '0'))
    } else {
      image.src = data;
      image.onload = function() {
        ctx.clearRect(0, 0, store.state.capture.rendersize.x, store.state.capture.rendersize.y);
        ctx.drawImage(image, 0, 0, store.state.capture.rendersize.x, store.state.capture.rendersize.y);
        capturer.capture( recordingCanvas )
      }
    }
  }
}

const stepAnimation = async function (segmentLength, tickCallback) {
  // Decide whether to play, stop, go to the next segment or loop
  // on the next frame
  if (store.state.time.segmentframe < segmentLength - 1) {
    await captureFrame()
    store.commit('time/incrementCurrentframe')
    if (store.state.time.sequencePlaying) requestAnimationFrame(tickCallback)
  } else if (store.state.camera.nextcamera < store.state.camera.all.length-1) {
    store.commit('camera/setThiscamera', store.state.camera.thiscamera + 1)
    store.commit('camera/setNextcamera', store.state.camera.nextcamera + 1)
    store.commit('time/setLength', (store.state.camera.all[store.state.camera.nextcamera].time - store.state.camera.all[store.state.camera.thiscamera].time) * store.state.time.fps)
    store.commit('camera/setSelectedcamera', store.state.camera.all[store.state.camera.thiscamera].id)

    console.log('Next camera', store.state.camera.nextcamera)
    await captureFrame()

    // store.commit('time/incrementCurrentframe')
    if (store.state.time.sequencePlaying) {
      console.log("store.state.time.sequencePlaying", store.state.time.sequencePlaying);
      requestAnimationFrame(tickCallback)
    }
  } else {
    // play = 2 means looping the sequence
    if (store.state.ui.navigation === 1) {
      store.commit('ui/setBlockNavigation', false)
    }
    if (store.state.ui.embed && store.state.time.play === 2) {
      store.commit('time/incrementLoopCount')
      if (store.state.time.loopAmount === null || store.state.time.loopCount < store.state.time.loopAmount) {
        console.log('Animation restart')
        store.commit('time/setCurrentframe', 0)
        store.commit('camera/setThiscamera', 0)
        store.commit('camera/setNextcamera', 1)
        store.commit('camera/setSelectedcamera', store.state.camera.all[store.state.camera.thiscamera].id)
        if (store.state.time.sequencePlaying) requestAnimationFrame(tickCallback)
      }
    } else {
      console.log('Animation done')
      store.commit('camera/setThiscamera', store.state.camera.thiscamera + 1)
      store.commit('camera/setNextcamera', 0)      
      if (store.state.capture.doCapture) {
        console.log('Stopping Capture')
        store.commit('capture/setDoCapture', false)
        if (store.state.capture.type === 'zip') {
          // Saving the frames into a ZIP
          // const data = await screenshot.getScreenshotPromise(512, 512, false)
          // screenshot.addImageToZip(zip, data, store.state.time.currentframe.toString().padStart(4, '0'))
          zip.generateAsync({type: 'blob'})
          .then(function(content) {
            saveAs(content, 'cinemafab_frames.zip')
          })

        } else {
          capturer.stop();
          
          if (store.state.capture.type === 'webm') {
            // Saving the webm
            capturer.save()
          } else if (store.state.capture.type === 'mp4') {
            // Transcoding to MP4
            // TODO: outsource this. It won't work for larger sizes
            capturer.save(async function(blob) {
              const buffer = await new Response(blob).arrayBuffer()
              transcodeToMp4(buffer)
            });
          }
        }
      }

      
      store.commit('camera/setSelectedcamera', store.state.camera.all[store.state.camera.nextcamera].id)
      store.commit('time/setSequencePlaying', false)
    }
  }
}

const transcodeToMp4 = function (blob) {
  let worker = ffmpegworker.setupWorker()
  worker.postMessage(
    {
      type: 'run',
      MEMFS: [{name: "test.webm", data: blob}],
      arguments: ['-i', 'test.webm', '-c:v', 'libx264', '-an', 'test.mp4'],
    }
  )
}

let zip = null
let capturer = null
let recordingCanvas = null
let ctx = null
let image = null

/* global CCapture */
const setTime = function (currenttime) {
  if (store.state.capture.doCapture) {
    
    if (store.state.capture.type === 'zip') {
      zip = new JSZip()
    } else {
      image = new Image();
      capturer = new CCapture( { format: 'webm' } );
      recordingCanvas = document.createElement("canvas");
      recordingCanvas.height = store.state.capture.rendersize.y;
      recordingCanvas.width = store.state.capture.rendersize.x;
      ctx = recordingCanvas.getContext("2d");
      // invert Y
      // ctx.scale(1.0, -1.0);
      capturer.start()
    }
    
  }

  store.commit('camera/setThiscamera', store.getters['camera/getThisCamera'](currenttime))
  store.commit('camera/setNextcamera', store.getters['camera/getNextCamera'](currenttime))
  if (store.state.camera.nextcamera !== null) {
    store.commit('time/setLength', (store.state.camera.all[store.state.camera.nextcamera].time - store.state.camera.all[store.state.camera.thiscamera].time) * store.state.time.fps)
  } else {
    // this.length = 10
    store.commit('camera/setNextcamera', store.state.camera.thiscamera)
  }
}

export default {
  getCameraTransform,
  setupAnimation,
  stepAnimation,
  setTime
}