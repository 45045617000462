<template>
    <v-app-bar
      app
      clipped-left
      v-if="!embed"
      extended
      class="px-4"
    >
      <v-btn
        fab
        dark
        color="pink darken-3"
      >
        <v-icon large>mdi-movie-open</v-icon>
      </v-btn>    
      
      <div class="ml-4 mt-2 text-h2 font-weight-normal">Cinemafab</div>

      <v-spacer></v-spacer>
      <v-btn
        text
        @click="captureDialog"
      >
        Capture settings
      </v-btn>
      <v-btn
        text
        @click="loadDialog"
      >
        Load model
      </v-btn>
      <v-btn
        text
        @click="importExportDialog"
      >
        Import Export
      </v-btn>

      <template v-slot:extension>
        <div class="text-subtitle-1">Direct movies from your Sketchfab models</div>
        <v-spacer></v-spacer>
        <v-sheet
          v-if="sceneLoaded"
          class="d-flex align-center py-1"
          height="48"
          color="transparent"
        >
        <v-switch
          class="mt-5"
          v-model="playercameraconstraints"
          @change="toggleCameraconstraints"
          label="Camera constraints"
        ></v-switch>
        <v-divider vertical class="mx-2"/>
        <v-switch
          class="mt-5"
          v-model="playervignette"
          @change="toggleVignette"
          label="Vignette"
        ></v-switch>
        <v-divider vertical class="mx-2"/>
        <v-switch
          class="mt-5"
          v-model="playergui"
          label="Sketchfab Gui"
        ></v-switch>
        <v-divider vertical class="mx-2"/>
        <v-btn
          outlined
          @click="toggleFullscreen"
        >
          <v-icon >{{ isFullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
          Fullscreen
        </v-btn>
        <v-divider
          vertical
          class="mx-2"
        />
        
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              :color="buttoncolor"
              v-bind="attrs"
              v-on="on"
              out
            >
              Background
            </v-btn>
          </template>
          <v-card>
            <v-color-picker
              mode="hexa"
              show-swatches
              v-model="color"
              @update:color="setBackground"
            ></v-color-picker>
          </v-card>
        </v-menu>    
        <v-divider
          vertical
          class="mx-2"
        />

        <v-sheet
          width="190"
          color="transparent"
        >
          <v-select
            v-model="ratio"
            :items="items"
            item-text="text"
            item-value="value"
            label="Pick aspect ratio"
            hint="Aspect ratio"
            dense
            persistent-hint
            return-object
            single-line
            @change="setratio"
            menu-props="{height: 600}"
          ></v-select>
        </v-sheet>
        </v-sheet>
      </template>
    </v-app-bar>
</template>

<script>
import background from '@/tools/sketchfab/background';
import camera from '@/tools/sketchfab/camera';
import postprocessing from '@/tools/sketchfab/postprocessing';
import viewer from '@/tools/viewer';
import screenfull from 'screenfull'

export default {
  name: "app-bar",

  data: () => ({
    ratio: {text: 'Full', value: null},
    items: [
      {text: 'Full', value: null},
      {text: '1/1', value: 1},
      {text: '16/9 landscape', value: 9/16},
      {text: '16/9 portrait', value: 16/9},
      {text: '4/3 landscape', value: 3/4},
      {text: '4/3 portrait', value: 4/3},
      {text: '1/2 landscape', value: 1/2},
      {text: '1/2 portrait', value: 2/1},
    ],
    color: '#FF00FF', //{ r: 192, g: 192, b: 192, a: 1 },
    buttoncolor: 'rgb(192,192,192)',
    isFullscreen: false,
  }),
  computed: {
    embed() {
      return this.$store.state.ui.embed
    },
    playergui: {
      get: function() {
        return this.$store.state.playergui
      },
      set: function(newValue) {
        this.$store.commit('setPlayergui', newValue)
      },
    },
    playervignette: {
      get: function() {
        return this.$store.state.playervignette
      },
      set: function(newValue) {
        this.$store.commit('setPlayervignette', newValue)
      },
    },
    playercameraconstraints: {
      get: function() {
        return this.$store.state.playercameraconstraints
      },
      set: function(newValue) {
        this.$store.commit('setPlayercameraconstraints', newValue)
      },
    },
    sceneLoaded() {
      return this.$store.state.sceneLoaded
    },

  },
  mounted () {
    screenfull.on('change', () => {
      this.isFullscreen = screenfull.isFullscreen
      
    })
  },
  methods: {
    setratio() {
      const container = document.getElementById('ratio_container');
      this.$store.commit('setContainersize', { x: container.clientWidth, y: container.clientHeight })
      this.$store.commit('setAspectratio', this.ratio.value)
    },
    setBackground() {
      const RGB = viewer.convertHexToRGB(this.color)
      this.buttoncolor = `rgb(${RGB.r},${RGB.g},${RGB.b})`
      background.setBackgroundHex(this.color)
      this.$store.commit('ui/setBackground', this.color)
    },
    async toggleFullscreen () {
      if (screenfull.isEnabled) {
        await screenfull.toggle(document.getElementById('ratio_container'));
        this.setratio()
      }  
    },
    toggleVignette(value) {
      postprocessing.setPostProcessing({vignetteEnable: value})
    },
    toggleCameraconstraints(value) {
      camera.setCameraConstraints(value)
    },
    loadDialog() {
      this.$store.commit('setDialogtab', 0)
      this.$store.commit('setLoaddialog', true)
    },
    importExportDialog() {
      this.$store.commit('setDialogtab', 1)
      this.$store.commit('setLoaddialog', true)
    },
    captureDialog() {
      this.$store.commit('setDialogtab', 2)
      this.$store.commit('setLoaddialog', true)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>