const setupWorker = function () {

  let worker = new Worker("./vendors/ffmpeg-worker-mp4.js");
  worker.onmessage = function(e) {
    const msg = e.data;
    switch (msg.type) {
      case "ready":
        worker.postMessage({type: "run", arguments: ["-version"]});
      break;
      case "stdout":
        console.log(msg.data);
      break;
      case "stderr":
        console.log(msg.data);
      break;
      case "done": {
        console.log(msg.data);
        if (msg.data.MEMFS.length > 0) {
          let blob = new Blob([msg.data.MEMFS[0].data], {
            type: "video/mp4"
          });

          let link = document.createElement('a')
          link.download = 'cinemafab.mp4';
          link.href = URL.createObjectURL(blob);
          link.click();
          URL.revokeObjectURL(link.href); 
        }
        
        break;
      }
    }
  };
  return worker
}
export default {
  setupWorker
}