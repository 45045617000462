import player from './player';

// /**
//  * Overlay a watermark over an image.
//  * @param {base64} imageData Screenshot image of the product
//  * @param {number} width width of the image
//  * @param {number} height height of the image
//  * @param {string} watermarkName Name of the watermark image file
//  * @returns promise with watermarked image data
//  */
//  const watermarkScreenshot = function (imageData, width, height, watermarkName) {
//   return new Promise (resolve => {
//       // Set up the base canvas with the same dimensions of the image
//       let canvas = document.createElement('canvas');
//       let ctx = canvas.getContext('2d');
//       canvas.width = width
//       canvas.height = height

//       let img = new Image()
//       img.src = imageData
//       img.onload = function(){
//           ctx.drawImage(img, 0, 0, width, height);

//           // Create the watermak image
//           let watermarkImage = new Image
//           watermarkImage.src = new URL(
//             `/data/assets/${watermarkName}.png`,
//             window.location.href
//           ).href;
          
//           watermarkImage.onload = function () {
//               ctx.globalAlpha = 0.25;
//               ctx.drawImage(watermarkImage, width - 550, 38, 512, 117)
//               let imageString = canvas.toDataURL()
//               resolve(imageString)
//           }
//           watermarkImage.onerror = function () {
//               let imageString = canvas.toDataURL()
//               resolve(imageString)
//           }
//       }; 
//   })
// }

const getScreenshotPromise = function (width, height) {
  return new Promise((resolve) => {
    player.api.getScreenShot(
      width,
      height,
      'image/png',
      function (err, result) {
        // result = result.replace('data:image/png;base64,', '')
        resolve(result)
      }
    );
  });
};

const downloadImageBlob = function (blob, filename) {
  const a = document.createElement('a');
  a.href = blob;
  a.target="_parent"
  a.download = `Cinemafab_${filename}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

const addImageToZip = function (zip, blob, idx) {
  blob = blob.replace('data:image/png;base64,', '')
  const imageName = `Cinemafab_${idx}.png`;
  zip.file(imageName, blob, {base64: true})
}


export default {
  getScreenshotPromise,
  downloadImageBlob,
  addImageToZip
}