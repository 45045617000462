import player from "./player";
import animate from "./animate";

const setCameraUnconstrained = function (eye, T, duration) {
  if (duration === null || duration === undefined) duration = 1;
  const target = T ? T : [0, 0, 0];
  return new Promise(resolve => {
    player.api.setCameraLookAt(
      eye,
      target,
      duration,
      function () {}
    );

    player.api.setCameraLookAtEndAnimationCallback(function () {
      resolve();
    });
  });
};

const getCamera = function () {
  return new Promise(resolve => {
    player.api.getCameraLookAt(function (err, camera) {
      resolve(camera);
    });
  });
};

const getFov = function () {
  return new Promise(resolve => {
    player.api.getFov(function (err, angle) {
      resolve(angle);
    });
  });
};

const setFov = function (fov) {
  if (fov === null) fov = 45;
  return new Promise(resolve => {
    player.api.setFov(fov, function () {
      resolve();
    });
  });
};

const setCameraConstraints = function (value) {
  return new Promise(resolve => {
    player.api.setEnableCameraConstraints(
      value,
      { preventFocus: true },
      function () {
        resolve();
      }
    );
  });
};

const captureCamera = async function() {
  let promises = []
  promises.push(getCamera())
  promises.push(getFov())
  const results = await Promise.all(promises)
  console.log("results[0].position", results[0].position);
  let cam = {
    position: results[0].position,
    target: results[0].target,
    fov: results[1],
    latlon: animate.getLatLonFromPos(results[0].position, results[0].target),
    radius: animate.getRadius(results[0].position, results[0].target),
    time: 0,
    spin: 0,
    roll: 0,
    circleRadius: 0,
    circleCount: 1,
    easing: 'sine',
    motion: 'orbit'
  }
  cam.width = animate.getVertigoWidth(cam.radius, cam.fov)
  return cam
}

const retakeCamera = async function() {
  let promises = []
  promises.push(getCamera())
  promises.push(getFov())
  const results = await Promise.all(promises)
  let cam = {
    position: results[0].position,
    target: results[0].target,
    latlon: animate.getLatLonFromPos(results[0].position, results[0].target),
    radius: animate.getRadius(results[0].position, results[0].target),
  }
  return cam
}

const setCamera = async function (pos, target, fov) {
  let promises = []
  promises.push(setCameraUnconstrained(pos, target, 0))
  promises.push(setFov(fov))
  await Promise.all(promises)
}

export default {
  // setCameraLookAt,
  getCamera,
  setFov,
  getFov,
  setCameraConstraints,
  setCameraUnconstrained,
  captureCamera,
  retakeCamera,
  setCamera,
};